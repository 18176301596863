// Imports
          import InputText from "../../../../components/inputs/InputText";
import React, { useEffect, useMemo,useContext } from 'react';

//setContent import { useState } from 'react';

import '../../../../App.css';
//useNavigate import { useNavigate } from 'react-router-dom';


//FontAwesomeIcon import * as fa from '@fortawesome/free-solid-svg-icons';
//FontAwesomeIcon import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component86Page() {
//useNavigate const navigate = useNavigate()


//setContent const [content,setContent]= useState( {} )   

//setContent const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events


//UseEffect




//DIV
  return <><div id='bloc0'  className={ "bg-red flex flex-row gap-10 items-center w-screen p-5 border-2 border-secondary_color-700"}     > <div id='bloc0_0'  className={ "h-11 w-2/12"}     > <img  id='bloc0_0_0' src="https://fs.appisyou.com/users/12/830_asmonacofc_logo1713968406842.jpg" className={ "w-16   object-cover"}     alt="undefined" /></div>
          <div id='bloc0_1'  className={ "flex flex-row items-center text-secondary_color-700 gap-10 w-6/12 justify-between"}     > <div id='bloc0_1_0'       >{`Accueil`} </div>
            <div id='bloc0_1_1'       >{`A propos`} </div>
            <div id='bloc0_1_2'       >{`Mentions légales`} </div>
            <div id='bloc0_1_3'       >{`Contact`} </div></div>
          <div id='bloc0_2'  className={ "flex flex-row gap-5 w-4/12 justify-around"}     > <div id='bloc0_2_0'  className={ "p-2 rounded-full bg-secondary_color-700 text-red"}     >{`Se connecter`} </div>
            <div id='bloc0_2_1'  className={ "p-2 rounded-full bg-green-400 text-red"}     >{`S'enregistrer`} </div></div></div><div id='bloc1'  className={ "w-full bg-red p-4"}     > <div id='bloc1_0'  className={ "flex gap-10 md:flex-row flex-col"}     > <div id='bloc1_0_0'  className={ "flex flex-col justify-center"}     > <div id='bloc1_0_0_0'  className={ "text-3xl text-white-700 font-bold text-justify mb-10"}     >{`L'AS Monaco incarne l'excellence, alliant tradition, talent et ambition sur les terrains du football français et européen.`} </div>
              <div id='bloc1_0_0_1'  className={ "text-white-700 text-start"}     >{` L'AS Monaco est un club de football professionnel basé à Monaco, une principauté située sur la Côte d'Azur en France. Fondé en 1924, le club est l'un des plus prestigieux de France et a connu de nombreux succès nationaux et internationaux au fil des ans.`} </div></div>
            <div id='bloc1_0_1'  className={ "flex gap-10 md:flex-row flex-col"}     > <div id='bloc1_0_1_0'  className={ "flex justify-center items-center"}     > <img  id='bloc1_0_1_0_0' src="https://fs.appisyou.com/users/12/groupe-monaco1714031292966.jpg" className={ "rounded-3xl   object-cover"}     alt="undefined" /></div>
              <div id='bloc1_0_1_1'  className={ "flex flex-col gap-10"}     > <div id='bloc1_0_1_1_0'       > <img  id='bloc1_0_1_1_0_0' src="https://fs.appisyou.com/users/12/salisu1714031637411.jpg" className={ "rounded-3xl   object-cover"}     alt="undefined" /></div>
                <div id='bloc1_0_1_1_1'       > <img  id='bloc1_0_1_1_1_0' src="https://fs.appisyou.com/users/12/kohn1714031681555.jpg" className={ "rounded-3xl   object-cover"}     alt="undefined" /></div></div>
              <div id='bloc1_0_1_2'  className={ "flex justify-center items-center"}     > <img  id='bloc1_0_1_2_0' src="https://fs.appisyou.com/users/12/zakaria1714031736921.jpg" className={ "rounded-3xl   object-cover"}     alt="undefined" /></div></div></div></div><div id='bloc2'  className={ "bg-secondary_color w-screen p-5 border-2 border-primary_color-700"}     > <div id='bloc2_0'  className={ "flex text-left flex-row gap-10 w-full"}     > <div id='bloc2_0_0'  className={ "text-primary_color-700 w-2/12 ml-4"}     > <div id='bloc2_0_0_0'  className={ "font-bold pb-3"}     >{`Solutions`} </div>
              <div id='bloc2_0_0_1'  className={ "pb-2"}     >{`Toto`} </div>
              <div id='bloc2_0_0_2'  className={ "pb-2"}     >{`Momo`} </div>
              <div id='bloc2_0_0_3'  className={ "pb-2"}     >{`Bobo`} </div></div>
            <div id='bloc2_0_1'  className={ "text-primary_color-700 w-3/12"}     > <div id='bloc2_0_1_0'  className={ "font-bold pb-3"}     >{`Support`} </div>
              <div id='bloc2_0_1_1'  className={ "pb-2"}     >{`Pricing`} </div>
              <div id='bloc2_0_1_2'  className={ "pb-2"}     >{`Api`} </div>
              <div id='bloc2_0_1_3'  className={ "pb-2"}     >{`Documentation`} </div></div>
            <div id='bloc2_0_2'  className={ "text-primary_color-700 w-3/12"}     > <div id='bloc2_0_2_0'  className={ "font-bold pb-3"}     >{`Company`} </div>
              <div id='bloc2_0_2_1'  className={ "pb-2"}     >{`Job`} </div>
              <div id='bloc2_0_2_2'  className={ "pb-2"}     >{`Press`} </div></div>
            <div id='bloc2_0_3'  className={ "w-3/12"}     > <div id='bloc2_0_3_0'  className={ "text-primary_color-700 font-bold pb-3"}     >{`Subscribe`} </div>
              <div id='bloc2_0_3_1'  className={ "text-primary_color-700 pb-2"}     >{`Vous pouvez vous inscrire a la news letter`} </div>
              <div id='bloc2_0_3_2'  className={ "flex flex-row gap-10 pb-2"}     > <InputText id={"input_bloc2_0_3_2_0"} label={"x"} onChange={ (value)=>{
        
        
                          
                       } } value={""} className={""} placeholder={"undefined"} />
                <div id='bloc2_0_3_2_1'  className={ "p-2 rounded-full bg-primary_color-700 text-secondary_color w-20 text-center"}     >{`Souscrire`} </div></div></div></div></div></>
  
}

export default Component86Page;
