// Imports
          
import React, { useEffect, useMemo,useContext } from 'react';

//setContent import { useState } from 'react';

import '../../../../App.css';
//useNavigate import { useNavigate } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component55Page() {
//useNavigate const navigate = useNavigate()


//setContent const [content,setContent]= useState( {} )   

//setContent const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events


//UseEffect




//DIV
  return <><div id='bloc0'  className={ "w-full flex flex-row justify-between items-center bg-orange-400 bg-primary_color safearea-top fixed top-0 left-0 z-40"}     > <img  id='bloc0_0' src="/profiles/ploy14/user.png" className={ "w-10 h-10 rounded-xl m-2   object-cover"}     alt="undefined" />
          <div id='bloc0_1'  className={ "font-bold text-base text-white"}     >{`Les matchs`} </div>
          <div id='bloc0_2'  className={ "m-4 rounded-full p-2  z-20 font-bold text-primary_color bg-white"}     >{`Déconnexion`} </div></div><div id='bloc1'  className={ "w-full p-2 bg-white justify-around flex flex-row items-center w-full p-2 bg-white"}     > <img  id='bloc1_0' src="https://fs.appisyou.com/apps/mike/Maghnes1712656085167.jpg" className={ "w-20 h-20 rounded-full p-2   object-cover"}     alt="undefined" />
          <div id='bloc1_1'       >{`Maghnes`} </div>
          <div id='bloc1_2'       >{`Akilouche`} </div></div><div id='bloc2'  className={ " flex centre bg-primary_color fixed bottom-0 left-0 w-screen z-50 text-white safearea-bottom w-full  items-center justify-center"}     > <div id='bloc2_0'  className={ "pt-1 flex flex-row w-full justify-evenly pb-8"}     > <div id='bloc2_0_0x0'  className={ "text-base flex flex-col p-2"}     > <FontAwesomeIcon  id='bloc2_0_0x0_0'  icon={ fa.faFutbolBall}        />
              <div id='bloc2_0_0x0_1'  className={ "text-xs"}     >{`Joueurs`} </div></div>
    <div id='bloc2_0_0x1'  className={ "text-base flex flex-col p-2"}     > <FontAwesomeIcon  id='bloc2_0_0x1_0'  icon={ fa.faShieldAlt}        />
              <div id='bloc2_0_0x1_1'  className={ "text-xs"}     >{`Clubs`} </div></div>
    <div id='bloc2_0_0x2'  className={ "text-base flex flex-col p-2"}     > <FontAwesomeIcon  id='bloc2_0_0x2_0'  icon={ fa.faCalendarDays}        />
              <div id='bloc2_0_0x2_1'  className={ "text-xs"}     >{`Matchs`} </div></div></div></div></>
  
}

export default Component55Page;
