import "../../App.css";

import {
  BrowserRouter,
  Routes,
  Route,

} from "react-router-dom";


import { useEffect, useState } from "react";
import { getAuthUser } from "../../service/mftc-api";


import * as serviceWorkerRegistration from "../../serviceWorkerRegistration";
import NotFound from "./pages/notfound/NotFound";
import Component53Page from "./pages/Component53Page/Component53Page";
import Component54Page from "./pages/Component54Page/Component54Page";
import Component55Page from "./pages/Component55Page/Component55Page";
import Component56Page from "./pages/Component56Page/Component56Page";
import Component62Page from "./pages/Component62Page/Component62Page";
import Component64Page from "./pages/Component64Page/Component64Page";
import Component76Page from "./pages/Component76Page/Component76Page";
import Component86Page from "./pages/Component86Page/Component86Page";
import Component87Page from "./pages/Component87Page/Component87Page";
import Component88Page from "./pages/Component88Page/Component88Page";


function AppClient() {



  const [authUser, setAuthUser] = useState(getAuthUser());









  useEffect(() => {
    serviceWorkerRegistration.updateRegister();
  }, []);


  const Init = () => {

      const isStandalone =
        window.matchMedia("(display-mode: standalone)").matches ||
        window.matchMedia("(display-mode: fullscreen)").matches;

if (window.navigator.standalone || isStandalone) {
                    return  !authUser ? <Component53Page/>    : <Component53Page/>
                          } else {
                    return  !authUser ? <Component55Page/>    : <Component55Page/>
                          }
  };


  return (

      <BrowserRouter>
        <Routes>
          <Route path="" element={<Init />} />
          <Route path="/" element={<Init />} />
          {
            }<Route path="/home" element={<Component53Page/>}/>
<Route path="/joueur" element={<Component54Page/>}/>
<Route path="/matchs" element={<Component55Page/>}/>
<Route path="/club" element={<Component56Page/>}/>
<Route path="/profilutilisateur" element={<Component62Page/>}/>
<Route path="/footmercato" element={<Component64Page/>}/>
<Route path="/contact" element={<Component76Page/>}/>
<Route path="/accueil" element={<Component86Page/>}/>
<Route path="/contactsport" element={<Component87Page/>}/>
<Route path="/mentions" element={<Component88Page/>}/>
{
          }
          
          <Route path="/404" element={<NotFound />} />
        </Routes>
      </BrowserRouter>

  );
}

export default AppClient;



