// Imports
          import { Link } from 'react-router-dom'
import React, { useEffect, useMemo,useContext } from 'react';

//setContent import { useState } from 'react';

import '../../../../App.css';
import { useNavigate } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component62Page() {
const navigate = useNavigate()


//setContent const [content,setContent]= useState( {} )   

//setContent const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events
const bloc1_2_1_onClick = async (e) => {
                try{ 
                      navigate('/joueur');
                }
                catch(e){
                  console.log("Error bloc1_2_1_onClick ")
                }  
              };

//UseEffect




//DIV
  return <><div id='bloc0'  className={ "w-full flex flex-row justify-between items-center safearea bg-black"}     > <img  id='bloc0_0' src="/profiles/ploy14/user.png" className={ "w-10 h-10 rounded-xl m-2   object-cover"}     alt="undefined" />
          <div id='bloc0_1'  className={ "font-bold text-base text-white"}     > </div>
          <div id='bloc0_2'  className={ "w-20  relative h-12 "}     > <FontAwesomeIcon  id='bloc0_2_0'  icon={ fa.faArrowRightToBracket}      className={ "p-4 text-white absolute"}  />
            <FontAwesomeIcon  id='bloc0_2_1'  icon={ fa.faHomeLgAlt}      className={ "p-4 text-white absolute"}  /></div></div><div id='bloc1'  className={ "w-full bg-white flex flex-col centre p-4 gap-2  items-center justify-center"}     > <img  id='bloc1_0' src="https://fs.appisyou.com/apps/mike/logo psg1712671482468.jpg" className={ "rounded-full w-40 h-40   object-cover"}     alt="undefined" />
          <div id='bloc1_1'  className={ "flex flex-row gap-4 "}     > <div id='bloc1_1_0'       >{`Martin`} </div>
            <div id='bloc1_1_1'       >{`Toto`} </div></div>
          <div id='bloc1_2'  className={ "flex flex-row gap-2"}     > <div id='bloc1_2_0'  className={ "p-2 rounded-full bg-red-400 text-white "}     >{`Déconnexion`} </div>
            <Link    to="/joueur"><div id='bloc1_2_1'  className={ "p-2 rounded-full bg-blue-400 text-white "}    onClick = { bloc1_2_1_onClick } >{`Home`} </div></Link></div></div><div id='bloc2'  className={ " flex centre bg-primary_color fixed bottom-0 left-0 w-screen z-50 text-white safearea-bottom w-full  items-center justify-center"}     > <div id='bloc2_0'  className={ "pt-1 flex flex-row w-full justify-evenly pb-8"}     > <div id='bloc2_0_0x0'  className={ "text-base flex flex-col p-2"}     > <FontAwesomeIcon  id='bloc2_0_0x0_0'  icon={ fa.faHomeAlt}        />
              <div id='bloc2_0_0x0_1'  className={ "text-xs"}     >{`Lien`} </div></div>
    <div id='bloc2_0_0x1'  className={ "text-base flex flex-col p-2"}     > <FontAwesomeIcon  id='bloc2_0_0x1_0'  icon={ fa.faHomeAlt}        />
              <div id='bloc2_0_0x1_1'  className={ "text-xs"}     >{`Lien`} </div></div>
    <div id='bloc2_0_0x2'  className={ "text-base flex flex-col p-2"}     > <FontAwesomeIcon  id='bloc2_0_0x2_0'  icon={ fa.faHomeAlt}        />
              <div id='bloc2_0_0x2_1'  className={ "text-xs"}     >{`Lien`} </div></div>
    <div id='bloc2_0_0x3'  className={ "text-base flex flex-col p-2"}     > <FontAwesomeIcon  id='bloc2_0_0x3_0'  icon={ fa.faHomeAlt}        />
              <div id='bloc2_0_0x3_1'  className={ "text-xs"}     >{`Lien`} </div></div>
    <div id='bloc2_0_0x4'  className={ "text-base flex flex-col p-2"}     > <FontAwesomeIcon  id='bloc2_0_0x4_0'  icon={ fa.faHomeAlt}        />
              <div id='bloc2_0_0x4_1'  className={ "text-xs"}     >{`Lien`} </div></div></div></div></>
  
}

export default Component62Page;
