// Imports
          import InputText from "../../../../components/inputs/InputText";
import React, { useEffect, useMemo,useContext } from 'react';

//setContent import { useState } from 'react';

import '../../../../App.css';
//useNavigate import { useNavigate } from 'react-router-dom';


//FontAwesomeIcon import * as fa from '@fortawesome/free-solid-svg-icons';
//FontAwesomeIcon import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component88Page() {
//useNavigate const navigate = useNavigate()


//setContent const [content,setContent]= useState( {} )   

//setContent const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events


//UseEffect




//DIV
  return <><div id='bloc0'  className={ "bg-red flex flex-row gap-10 items-center w-screen p-5 border-2 border-secondary_color-700"}     > <div id='bloc0_0'  className={ "h-11 w-2/12"}     > <img  id='bloc0_0_0' src="https://fs.appisyou.com/users/12/830_asmonacofc_logo1713968406842.jpg" className={ "w-16   object-cover"}     alt="undefined" /></div>
          <div id='bloc0_1'  className={ "flex flex-row items-center text-red-700 gap-10 w-6/12 justify-between"}     > <div id='bloc0_1_0'       >{`Accueil`} </div>
            <div id='bloc0_1_1'       >{`A propos`} </div>
            <div id='bloc0_1_2'       >{`Mentions légales`} </div>
            <div id='bloc0_1_3'       >{`Contact`} </div></div>
          <div id='bloc0_2'  className={ "flex flex-row gap-5 w-4/12 justify-around"}     > <div id='bloc0_2_0'  className={ "p-2 rounded-full bg-red-700 text-white"}     >{`Se connecter`} </div>
            <div id='bloc0_2_1'  className={ "p-2 rounded-full bg-green-400 text-white"}     >{`S'enregistrer`} </div></div></div><div id='bloc1'  className={ "w-full p-2 bg-white"}     > <div id='bloc1_0'  className={ "p-10"}     > <div id='bloc1_0_0'       > <div id='bloc1_0_0_0'  className={ "font-bold text-3xl text-red-700 mb-5"}     >{`MENTIONS LEGALES `} </div>
              <div id='bloc1_0_0_1'  className={ "text-red-700 mb-5 text-justify"}     >{`parcourir ses pages. En vous connectant sur ce site, vous acceptez, sans réserves, les présentes modalités.  Aussi, conformément à l’article n°6 de la Loi n°2004-575 du 21 Juin 2004 pour la confiance dans l’économie numérique, les responsables du présent site internet www.asmonaco.fr sont :`} </div></div>
            <div id='bloc1_0_1'       > <div id='bloc1_0_1_0'  className={ "text-red-700 font-bold text-xl text-left mb-5"}     >{`Edition du site`} </div>
              <div id='bloc1_0_1_1'  className={ "mb-5 text-red-700 text-left pl-10"}     >{`fjzefjh efpejfjezf pefpejfpejzfpjzepof pfepfpzenfzkenf nfpenfkeznfkze fenkznfklz`} </div></div>
            <div id='bloc1_0_2'       > <div id='bloc1_0_2_0'  className={ "text-red-700 font-bold text-xl text-left mb-5"}     >{`Hébergement`} </div>
              <div id='bloc1_0_2_1'  className={ "mb-5 text-red-700 text-left pl-10"}     >{`Le site est hébergé par la société AppIsYou , situé au 10 RUE DE PARIS 35000 RENNES, numéro 01-48-53-96-87.`} </div></div>
            <div id='bloc1_0_3'       > <div id='bloc1_0_3_0'  className={ "text-red-700 font-bold text-xl text-left mb-5"}     >{`Directeur de publication`} </div>
              <div id='bloc1_0_3_1'  className={ "mb-5 text-red-700 text-left pl-10"}     >{`Le directeur de la publication du Site est Michaël LACHAND.`} </div></div>
            <div id='bloc1_0_4'       > <div id='bloc1_0_4_0'  className={ "text-red-700 font-bold text-xl text-left mb-5"}     >{`Nous contacter`} </div>
              <div id='bloc1_0_4_1'  className={ "mb-5 text-red-700 text-left pl-10"}     >{`Par téléphone : 01-75-96-32-69`} </div>
              <div id='bloc1_0_4_2'  className={ "mb-5 text-red-700 text-left pl-10"}     >{`Par  mail : asmonaco@mc.fr `} </div>
              <div id='bloc1_0_4_3'  className={ "mb-5 text-red-700 text-left pl-10"}     >{`Par courrier : 30 Avenue de la turbie 98000 MONACO `} </div></div>
            <div id='bloc1_0_5'       > <div id='bloc1_0_5_0'  className={ "text-red-700 font-bold text-xl text-left mb-5"}     >{`Données personnelles`} </div>
              <div id='bloc1_0_5_1'  className={ "mb-5 text-red-700 text-left pl-10"}     >{`jduoz p,poepofez pijfiejfpezjfpz bueofzeofn ofozenfznef oifoznefiozne `} </div></div></div></div><div id='bloc2'  className={ "bg-secondary_color w-screen p-5 border-2 border-primary_color-700"}     > <div id='bloc2_0'  className={ "flex text-left flex-row gap-10 w-full"}     > <div id='bloc2_0_0'  className={ "text-red-700 w-2/12 ml-4"}     > <div id='bloc2_0_0_0'  className={ "font-bold pb-3"}     >{`Solutions`} </div>
              <div id='bloc2_0_0_1'  className={ "pb-2"}     >{`Toto`} </div>
              <div id='bloc2_0_0_2'  className={ "pb-2"}     >{`Momo`} </div>
              <div id='bloc2_0_0_3'  className={ "pb-2"}     >{`Bobo`} </div></div>
            <div id='bloc2_0_1'  className={ "text-red-700 w-3/12"}     > <div id='bloc2_0_1_0'  className={ "font-bold pb-3"}     >{`Support`} </div>
              <div id='bloc2_0_1_1'  className={ "pb-2"}     >{`Pricing`} </div>
              <div id='bloc2_0_1_2'  className={ "pb-2"}     >{`Api`} </div>
              <div id='bloc2_0_1_3'  className={ "pb-2"}     >{`Documentation`} </div></div>
            <div id='bloc2_0_2'  className={ "text-red-700 w-3/12"}     > <div id='bloc2_0_2_0'  className={ "font-bold pb-3"}     >{`Company`} </div>
              <div id='bloc2_0_2_1'  className={ "pb-2"}     >{`Job`} </div>
              <div id='bloc2_0_2_2'  className={ "pb-2"}     >{`Press`} </div></div>
            <div id='bloc2_0_3'  className={ "w-3/12"}     > <div id='bloc2_0_3_0'  className={ "text-red-700 font-bold pb-3"}     >{`Subscribe`} </div>
              <div id='bloc2_0_3_1'  className={ "text-red-700 pb-2"}     >{`Vous pouvez vous inscrire a la news letter`} </div>
              <div id='bloc2_0_3_2'  className={ "flex flex-row gap-10 pb-2"}     > <InputText id={"input_bloc2_0_3_2_0"} label={"x"} onChange={ (value)=>{
        
        
                          
                       } } value={""} className={""} placeholder={"undefined"} />
                <div id='bloc2_0_3_2_1'  className={ "p-2 rounded-full bg-red-700 text-white w-20 text-center"}     >{`Souscrire`} </div></div></div></div></div></>
  
}

export default Component88Page;
