// Imports
          import InputText from "../../../../components/inputs/InputText";
import InputPhoneNumber from "../../../../components/inputs/inputPhoneNumber/InputPhoneNumber";
import InputTextArea from "../../../../components/inputs/InputTextArea";
import React, { useEffect, useMemo,useContext } from 'react';

import { useState } from 'react';

import '../../../../App.css';
//useNavigate import { useNavigate } from 'react-router-dom';


//FontAwesomeIcon import * as fa from '@fortawesome/free-solid-svg-icons';
//FontAwesomeIcon import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component87Page() {
//useNavigate const navigate = useNavigate()


const [content,setContent]= useState( {} )   

const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events


//UseEffect




//DIV
  return <><div id='bloc0'  className={ "bg-red flex flex-row gap-10 items-center w-screen p-5 border-2 border-secondary_color-700"}     > <div id='bloc0_0'  className={ "h-11 w-2/12"}     > <img  id='bloc0_0_0' src="https://fs.appisyou.com/users/12/830_asmonacofc_logo1713968406842.jpg" className={ "w-16   object-cover"}     alt="undefined" /></div>
          <div id='bloc0_1'  className={ "flex flex-row items-center text-red-700 gap-10 w-6/12 justify-between"}     > <div id='bloc0_1_0'       >{`Accueil`} </div>
            <div id='bloc0_1_1'       >{`A propos`} </div>
            <div id='bloc0_1_2'       >{`Mentions légales`} </div>
            <div id='bloc0_1_3'       >{`Contact`} </div></div>
          <div id='bloc0_2'  className={ "flex flex-row gap-5 w-4/12 justify-around"}     > <div id='bloc0_2_0'  className={ "p-2 rounded-full bg-red-700 text-white"}     >{`Se connecter`} </div>
            <div id='bloc0_2_1'  className={ "p-2 rounded-full bg-green-400 text-white"}     >{`S'enregistrer`} </div></div></div><div id='bloc1'  className={ "p-2 bg-white flex w-screen centre  items-center justify-center"}     > <div id='bloc1_0'  className={ "text-red-700 font-bold p-10 border-2 border-red-700"}     > <div id='bloc1_0_0'  className={ "text-2xl mb-10"}     >{`Formulaire de contact`} </div>
            <div id='bloc1_0_1'  className={ "flex flex-row gap-6 mb-5"}     > <div id='bloc1_0_1_0'       > <div id='bloc1_0_1_0_0'  className={ "text-left pb-3"}     >{`Nom`} </div>
                <InputText id={"input_bloc1_0_1_0_1"} label={"Nom"} onChange={ (value)=>{
        
        
                          setInternal( "Nom",value)
                       } } value={content[ "Nom"]??''} className={"border-red-700"} placeholder={"Nom"} /></div>
              <div id='bloc1_0_1_1'       > <div id='bloc1_0_1_1_0'  className={ "text-left mb-3"}     >{`Prénom`} </div>
                <InputText id={"input_bloc1_0_1_1_1"} label={"Prénom"} onChange={ (value)=>{
        
        
                          setInternal( "Prénom",value)
                       } } value={content[ "Prénom"]??''} className={""} placeholder={"Prénom"} /></div></div>
            <div id='bloc1_0_2'  className={ "mb-5"}     > <div id='bloc1_0_2_0'  className={ "text-left mb-3"}     >{`Mail`} </div>
              <InputText id={"input_bloc1_0_2_1"} label={"Adresse Mail"} onChange={ (value)=>{
      
      
                        setInternal( "Adresse Mail",value)
                     } } value={content[ "Adresse Mail"]??''} className={""} placeholder={"Adresse Mail"} /></div>
            <div id='bloc1_0_3'  className={ "mb-5"}     > <div id='bloc1_0_3_0'  className={ "text-left mb-3"}     >{`Téléphone`} </div>
              <InputPhoneNumber  id={"input_bloc1_0_3_1"} label={"Téléphone"} onChange={ (value)=>{
      
      
                        setInternal( "Téléphone",value)
                     } } value={content[ "Téléphone"]??''} className={""} placeholder={"Téléphone"}  /></div>
            <div id='bloc1_0_4'  className={ "mb-5"}     > <div id='bloc1_0_4_0'  className={ "text-left mb-3"}     >{`Message`} </div>
              <InputTextArea id={"input_bloc1_0_4_1"} label={"Message"} onChange={ (value)=>{
      
      
                        setInternal( "Message",value)
                     } } value={content[ "Message"]??''} className={""} placeholder={"Message"}  /></div>
            <div id='bloc1_0_5'       > <div id='bloc1_0_5_0'  className={ "text-white bg-red-700 rounded-full p-3"}     >{`Envoyer votre message`} </div></div></div></div><div id='bloc2'  className={ "bg-secondary_color w-screen p-5 border-2 border-primary_color-700"}     > <div id='bloc2_0'  className={ "flex text-left flex-row gap-10 w-full"}     > <div id='bloc2_0_0'  className={ "text-red-700 w-2/12 ml-4"}     > <div id='bloc2_0_0_0'  className={ "font-bold pb-3"}     >{`Solutions`} </div>
              <div id='bloc2_0_0_1'  className={ "pb-2"}     >{`Toto`} </div>
              <div id='bloc2_0_0_2'  className={ "pb-2"}     >{`Momo`} </div>
              <div id='bloc2_0_0_3'  className={ "pb-2"}     >{`Bobo`} </div></div>
            <div id='bloc2_0_1'  className={ "text-red-700 w-3/12"}     > <div id='bloc2_0_1_0'  className={ "font-bold pb-3"}     >{`Support`} </div>
              <div id='bloc2_0_1_1'  className={ "pb-2"}     >{`Pricing`} </div>
              <div id='bloc2_0_1_2'  className={ "pb-2"}     >{`Api`} </div>
              <div id='bloc2_0_1_3'  className={ "pb-2"}     >{`Documentation`} </div></div>
            <div id='bloc2_0_2'  className={ "text-red-700 w-3/12"}     > <div id='bloc2_0_2_0'  className={ "font-bold pb-3"}     >{`Company`} </div>
              <div id='bloc2_0_2_1'  className={ "pb-2"}     >{`Job`} </div>
              <div id='bloc2_0_2_2'  className={ "pb-2"}     >{`Press`} </div></div>
            <div id='bloc2_0_3'  className={ "w-3/12"}     > <div id='bloc2_0_3_0'  className={ "text-red-700 font-bold pb-3"}     >{`Subscribe`} </div>
              <div id='bloc2_0_3_1'  className={ "text-red-700 pb-2"}     >{`Vous pouvez vous inscrire a la news letter`} </div>
              <div id='bloc2_0_3_2'  className={ "flex flex-row gap-10 pb-2"}     > <InputText id={"input_bloc2_0_3_2_0"} label={"x"} onChange={ (value)=>{
        
        
                          
                       } } value={""} className={""} placeholder={"undefined"} />
                <div id='bloc2_0_3_2_1'  className={ "p-2 rounded-full bg-red-700 text-white w-20 text-center"}     >{`Souscrire`} </div></div></div></div></div></>
  
}

export default Component87Page;
