// Imports
          
import React, { useEffect, useMemo,useContext } from 'react';

//setContent import { useState } from 'react';

import '../../../../App.css';
//useNavigate import { useNavigate } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component56Page() {
//useNavigate const navigate = useNavigate()


//setContent const [content,setContent]= useState( {} )   

//setContent const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events


//UseEffect




//DIV
  return <><div id='bloc0'  className={ "w-full flex flex-row justify-between items-center bg-orange-400 bg-primary_color safearea-top fixed top-0 left-0 z-40"}     > <img  id='bloc0_0' src="/profiles/ploy14/user.png" className={ "w-10 h-10 rounded-xl m-2   object-cover"}     alt="undefined" />
          <div id='bloc0_1'  className={ "font-bold text-base text-white"}     >{`Page club`} </div>
          <div id='bloc0_2'  className={ "m-4 rounded-full p-2  z-20 font-bold text-primary_color bg-white"}     >{`Déconnexion`} </div></div><div id='bloc1'  className={ " w-full bg-black bg-primary_color p-6 flex md:flex-row flex-col justify-center items-start text-white"}     > <div id='bloc1_0'  className={ "text-4xl font-bold text-left my-2 md:w-1/3 px-4"}     >{`La liberté de créer rapidement une appli Mobile qui vous ressemble, Modif2`} </div>
          <div id='bloc1_1'  className={ "flex flex-col justify-start items-start md:w-1/3"}     > <div id='bloc1_1_0'  className={ " my-2 text-lg text-left"}     >{`Créez à la fois une appli mobile et un site internet professionnel pour promouvoir votre entreprise et présenter votre travail en ligne. Lancez votre boutique en ligne ou votre blog - avec Wix, les possibilités sont infinies.`} </div>
            <div id='bloc1_1_1'       > <div id='bloc1_1_1_0'  className={ "border-b font-bold  border-white text-left inline "}     >{`Créer un site internet`} </div>
              <div id='bloc1_1_1_1'  className={ "mx-2"}     >{`->`} </div></div></div></div><div id='bloc2'  className={ " flex centre bg-primary_color fixed bottom-0 left-0 w-screen z-50 text-white safearea-bottom w-full  items-center justify-center"}     > <div id='bloc2_0'  className={ "pt-1 flex flex-row w-full justify-evenly pb-8"}     > <div id='bloc2_0_0x0'  className={ "text-base flex flex-col p-2"}     > <FontAwesomeIcon  id='bloc2_0_0x0_0'  icon={ fa.faFutbolBall}        />
              <div id='bloc2_0_0x0_1'  className={ "text-xs"}     >{`Joueurs`} </div></div>
    <div id='bloc2_0_0x1'  className={ "text-base flex flex-col p-2"}     > <FontAwesomeIcon  id='bloc2_0_0x1_0'  icon={ fa.faShieldAlt}        />
              <div id='bloc2_0_0x1_1'  className={ "text-xs"}     >{`Clubs`} </div></div>
    <div id='bloc2_0_0x2'  className={ "text-base flex flex-col p-2"}     > <FontAwesomeIcon  id='bloc2_0_0x2_0'  icon={ fa.faCalendarDays}        />
              <div id='bloc2_0_0x2_1'  className={ "text-xs"}     >{`Matchs`} </div></div></div></div></>
  
}

export default Component56Page;
