// Imports
          
import React, { useEffect, useMemo,useContext } from 'react';

//setContent import { useState } from 'react';

import '../../../../App.css';
//useNavigate import { useNavigate } from 'react-router-dom';


//FontAwesomeIcon import * as fa from '@fortawesome/free-solid-svg-icons';
//FontAwesomeIcon import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component53Page() {
//useNavigate const navigate = useNavigate()


//setContent const [content,setContent]= useState( {} )   

//setContent const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events


//UseEffect




//DIV
  return <><div id='bloc0'  className={ "w-full flex flex-row justify-between items-center bg-zinc-700 bg-secondary_color safearea"}     > <img  id='bloc0_0' src="/profiles/ploy14/user.png" className={ "w-10 h-10 rounded-xl m-2   object-cover"}     alt="undefined" />
          <div id='bloc0_1'  className={ "m-4 rounded-full py-2 px-8  z-20 font-bold text-primary_color bg-white"}     >{`Login`} </div></div><div id='bloc1'  className={ " w-full bg-black bg-primary_color p-6 flex md:flex-row flex-col justify-center items-start text-white"}     > <div id='bloc1_0'  className={ "text-4xl font-bold text-left my-2 md:w-1/3 px-4"}     >{`La liberté de créer rapidement une appli Mobile qui vous ressemble, Modif2`} </div>
          <div id='bloc1_1'  className={ "flex flex-col justify-start items-start md:w-1/3"}     > <div id='bloc1_1_0'  className={ " my-2 text-lg text-left"}     >{`Créez à la fois une appli mobile et un site internet professionnel pour promouvoir votre entreprise et présenter votre travail en ligne. Lancez votre boutique en ligne ou votre blog - avec Wix, les possibilités sont infinies.`} </div>
            <div id='bloc1_1_1'       > <div id='bloc1_1_1_0'  className={ "border-b font-bold  border-white text-left inline "}     >{`Créer un site internet`} </div>
              <div id='bloc1_1_1_1'  className={ "mx-2"}     >{`->`} </div></div></div></div><div id='bloc2'  className={ "w-full bg-black bg-primary_color flex flex-row justify-center"}     > <div id='bloc2_0'  className={ "    p-2 h  grid  grids-cols-1  md:grid-cols-2  lg:grids-cols-3  xl:grid-cols-4 gap-1 text-white"}     > <div id='bloc2_0_0'  className={ " flex flex-col items-start p-4"}     > <div id='bloc2_0_0_0'  className={ "text-xl font-bold"}     >{`Communauté`} </div>
              <div id='bloc2_0_0_1'       >{`Réseaux sociaux`} </div>
              <div id='bloc2_0_0_2'       >{`Parrainnage`} </div>
              <div id='bloc2_0_0_3'       >{`Forums privés`} </div></div>
            <div id='bloc2_0_1'  className={ " flex flex-col items-start p-4"}     > <div id='bloc2_0_1_0'  className={ "font-bold text-xl"}     >{`Solutions`} </div>
              <div id='bloc2_0_1_1'       >{`Communication`} </div>
              <div id='bloc2_0_1_2'       >{`Gestion clientèle`} </div>
              <div id='bloc2_0_1_3'       >{`Boutique en ligne`} </div>
              <div id='bloc2_0_1_4'       > </div></div>
            <div id='bloc2_0_2'  className={ " flex flex-col items-start p-4"}     > <div id='bloc2_0_2_0'  className={ "font-bold text-xl"}     >{`Mentions légales`} </div>
              <div id='bloc2_0_2_1'       >{`Conditions générales d'utilisation`} </div>
              <div id='bloc2_0_2_2'       >{`Conditions générales de vente`} </div>
              <div id='bloc2_0_2_3'       >{`Politique de confidentialité`} </div></div>
            <div id='bloc2_0_3'  className={ " flex flex-col items-start p-4"}     > <div id='bloc2_0_3_0'  className={ "text-3xl font-bold"}     >{`Mike`} </div>
              <div id='bloc2_0_3_1'  className={ "text-xl"}     >{`L'app c'est vous!`} </div>
              <div id='bloc2_0_3_2'  className={ "underline font-bold"}     >{`A propos`} </div>
              <div id='bloc2_0_3_3'  className={ "underline font-bold"}     >{`Contact`} </div></div></div></div></>
  
}

export default Component53Page;
