// Imports
          
import React, { useEffect, useMemo,useContext } from 'react';

import { useState } from 'react';

import '../../../../App.css';
//useNavigate import { useNavigate } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component64Page() {
//useNavigate const navigate = useNavigate()


const [content,setContent]= useState( {} )   

const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events
useEffect(()=>{  action_event_447_bloc1()   },[])

const action_447 =   async ({TemplateConsent,setTemplateConsent})=> {
        try { 
        window.alert("TEST OK")
}
        catch (error) {
        console.error(error);
        }
      };  

const action_event_447_bloc1 =  async(event)=>{

                            
                           
              
                          await action_447( { event }) 
              
                          };  

const action_453 =   async ({Etat,setEtat})=> {
        try { 
        setEtat(!Etat)
}
        catch (error) {
        console.error(error);
        }
      };  

const setEtatbloc1_1_1 =(value)=> setInternal("etat1",value);

const action_event_453_bloc1_1_1 =  async(event)=>{

                            
                           var Etat = content["etat1"];
              
                          await action_453( { Etat,event, setEtat:setEtatbloc1_1_1  }) 
              
                          };  

const action_454 =   async ({Etat,setEtat})=> {
        try { 
        setEtat(Etat!=="vert" ? "vert":"rose")
}
        catch (error) {
        console.error(error);
        }
      };  

const setEtatbloc1_1_2 =(value)=> setInternal("etat2",value);

const action_event_454_bloc1_1_2 =  async(event)=>{

                            
                           var Etat = content["etat2"];
              
                          await action_454( { Etat,event, setEtat:setEtatbloc1_1_2  }) 
              
                          };  

//UseEffect




//DIV
  return <><div id='bloc0'  className={ "w-full flex flex-row justify-between items-center safearea bg-black"}     > <img  id='bloc0_0' src="/profiles/ploy14/user.png" className={ "w-10 h-10 rounded-xl m-2   object-cover"}     alt="undefined" />
          <div id='bloc0_1'  className={ "font-bold text-base text-white"}     > </div>
          <div id='bloc0_2'  className={ "w-20  relative h-12 "}     > <FontAwesomeIcon  id='bloc0_2_0'  icon={ fa.faArrowRightToBracket}      className={ "p-4 text-white absolute"}  />
            <FontAwesomeIcon  id='bloc0_2_1'  icon={ fa.faHomeLgAlt}      className={ "p-4 text-white absolute"}  /></div></div><div id='bloc1'  className={ "w-full flex flex-col justify-center items-center w-full flex flex-col justify-center items-center py-4 safearea-top"}    onStart = { action_event_447_bloc1} > <div id='bloc1_0'  className={ "h-40  w-full bg-orange-400  bg-primary_color"}     > </div>
          <div id='bloc1_1'  className={ "   -mt-32 -mb-32    md:-my-20 z-10 flex flex-col items-center justify-center md:flex-row"}     > <div id='bloc1_1_0'  className={ "bg-white  w-40 h-40 z-10 rounded-xl p-2"}     > <img  id='bloc1_1_0_0' src="undefined" className={ "w-full h-full rounded-xl   object-cover"}     alt="undefined" /></div>
            <div id='bloc1_1_1'  className={ " py-2 bg-zinc-900 flex flex-row items-center rounded-lg border-white"}    onClick = { action_event_453_bloc1_1_1} > <FontAwesomeIcon  id='bloc1_1_1_0'  icon={ fa.faDownload}      className={ "w-8 h-8 text-white"}  />
              <div id='bloc1_1_1_1'  className={ "px-1 flex flex-col justify-center items-center  "}     > <div id='bloc1_1_1_1_0'  className={ "text-white text-xs"}     >{`Install with`} </div>
                <div id='bloc1_1_1_1_1'  className={ "text-white text-xl font-extrabold"}     >{`AppIsYou`} </div></div></div>
            <div id='bloc1_1_2'  className={ " py-2 bg-zinc-900 flex flex-row items-center rounded-lg border-white"}    onClick = { action_event_454_bloc1_1_2} > <FontAwesomeIcon  id='bloc1_1_2_0'  icon={ fa.faDownload}      className={ "w-8 h-8 text-white"}  />
              <div id='bloc1_1_2_1'  className={ "px-1 flex flex-col justify-center items-center  "}     > <div id='bloc1_1_2_1_0'  className={ "text-white text-xs"}     >{`Install with`} </div>
                <div id='bloc1_1_2_1_1'  className={ "text-white text-xl font-extrabold"}     >{`AppIsYou`} </div></div></div></div>
          <div id='bloc1_2'  className={ "h-40  w-full bg-blue-200 bg-secondary_color"}     > </div></div><div id='bloc2'  className={ "w-full bg-black bg-primary_color flex flex-row justify-center"}     > <div id='bloc2_0'  className={ "    p-2 h  grid  grids-cols-1  md:grid-cols-2  lg:grids-cols-3  xl:grid-cols-4 gap-1 text-white"}     > <div id='bloc2_0_0'  className={ " flex flex-col items-start p-4"}     > <div id='bloc2_0_0_0'  className={ "text-xl font-bold"}     >{`Communauté`} </div>
              <div id='bloc2_0_0_1'       >{`Réseaux sociaux`} </div>
              <div id='bloc2_0_0_2'       >{`Parrainnage`} </div>
              <div id='bloc2_0_0_3'       >{`Forums privés`} </div></div>
            <div id='bloc2_0_1'  className={ " flex flex-col items-start p-4"}     > <div id='bloc2_0_1_0'  className={ "font-bold text-xl"}     >{`Solutions`} </div>
              <div id='bloc2_0_1_1'       >{`Communication`} </div>
              <div id='bloc2_0_1_2'       >{`Gestion clientèle`} </div>
              <div id='bloc2_0_1_3'       >{`Boutique en ligne`} </div>
              <div id='bloc2_0_1_4'       > </div></div>
            <div id='bloc2_0_2'  className={ " flex flex-col items-start p-4"}     > <div id='bloc2_0_2_0'  className={ "font-bold text-xl"}     >{`Mentions légales`} </div>
              <div id='bloc2_0_2_1'       >{`Conditions générales d'utilisation`} </div>
              <div id='bloc2_0_2_2'       >{`Conditions générales de vente`} </div>
              <div id='bloc2_0_2_3'       >{`Politique de confidentialité`} </div></div>
            <div id='bloc2_0_3'  className={ " flex flex-col items-start p-4"}     > <div id='bloc2_0_3_0'  className={ "text-3xl font-bold"}     >{`Mike`} </div>
              <div id='bloc2_0_3_1'  className={ "text-xl"}     >{`L'app c'est vous!`} </div>
              <div id='bloc2_0_3_2'  className={ "underline font-bold"}     >{`A propos`} </div>
              <div id='bloc2_0_3_3'  className={ "underline font-bold"}     >{`Contact`} </div></div></div></div></>
  
}

export default Component64Page;
